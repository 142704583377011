<template>
<!-- 找回密码 -->
  <div class="findPassword">
      <div class="findPasswordInner">
          <div class="findp-main">
            <div class="findp-tit">
              <h3>找回密码</h3> 
            </div>
            <div class="findp-cont">
                <div class="findp-cont-tab" :class="step == 1 ? 'step1' : step == 2 ? 'step2' : 'step3'">
                    <el-row>
                        <el-col :span="8">
                            <div class="step1-1">
                                1.选择找回密码方式
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="step2-1">
                                2.账号验证
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="step3-1">
                                3.重置密码
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="findp-item" v-show="step == 1">
                    <el-row>
                        <el-col :span="12">
                            <div class="type1" @click="phoneFind()">
                                <div class="type1-icon"></div>
                                <p class="txt">手机找回</p>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="type2" @click="emailFind()">
                                <div class="type2-icon"></div>
                                <p class="txt">邮箱找回</p>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="findp-item2"  v-show="step == 2">
                    <div v-show="step2 == 1">
                        <el-form :model="phoneMsg" :rules="rules" ref="phoneMsg" label-width="0">
                            <el-form-item prop="username_phone">
                                <el-input v-model="phoneMsg.username_phone" placeholder="请输入用户名*"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="reg-row">
                            <button class="reg-btn" @click="reactPasswordPhone()">发送验证短信</button>
                        </div> 
                        <div class="useEmail" @click="emailFind()">使用邮箱找回密码</div> 
                    </div>
                    <div v-show="step2 == 2">
                        <el-form :model="emailMsg" :rules="erules" ref="emailMsg" label-width="0">
                            <el-form-item prop="username">
                                <el-input v-model="emailMsg.username" placeholder="请输入用户名*"></el-input>
                            </el-form-item>
                            <div class="reg-row">
                                <button class="reg-btn" type="button" @click="sendEmail">发送验证邮件</button>
                            </div>
                        </el-form>
                        <div class="useEmail" @click="phoneFind()">使用手机号找回密码</div>
                    </div>
                </div>
                <div class="findp-item3" v-show="step == 3">
                     <el-form :model="resetMsg" :rules="rrules" ref="resetMsg" label-width="0">
                        <el-form-item prop="username" :rules="type == 'phone' ? rrules.username : []" v-if="type == 'phone'">
                            <el-input v-model="resetMsg.username" disabled></el-input>
                        </el-form-item>
                        <el-form-item prop="phone" :rules="type == 'phone' ? rrules.phone : []" v-if="type == 'phone'">
                            <el-input v-model="resetMsg.phone" placeholder="请输入手机号*"></el-input>
                        </el-form-item>
                        <el-form-item prop="code" :rules="type == 'phone' ? rrules.code : []" v-if="type == 'phone'">
                            <el-input v-model="resetMsg.code" placeholder="请输入手机验证码*" @input="resetMsg.code = resetMsg.code.replace(/[^\d.]/g,'');"></el-input>
                        </el-form-item> 
                        <el-form-item prop="password">
                            <el-input type="password" v-model="resetMsg.password" placeholder="请输入新密码*"></el-input>
                        </el-form-item>
                        <el-form-item prop="PasswordAgain">
                            <el-input type="password" v-model="resetMsg.PasswordAgain" placeholder="请再次输入密码*"></el-input>
                        </el-form-item>
                        <div class="reg-row">
                            <button class="reg-btn" type="button" @click="find">重置密码</button>
                        </div>
                     </el-form>
                </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
    import FindPassword from './FindPassword.js';
    export default FindPassword;
</script>

<style scoped>
    @import './FindPassword.css';
</style>