import FindPassword from './FindPassword.vue'
import axios from 'axios'
export default {
    components:{
        FindPassword
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.resetMsg.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        var validatePhone = (rule, value, callback) => {
            var myreg = /(^1|^84)(\d{10}|\d{9})$/;
            if(!myreg.test(value)) {
                this.phoneShow = true;
                callback(new Error('手机号格式不正确'));
            } else {
                callback();
            }
        }
        return {
            step:1,
            step2:1,
            token: '',
            // 手机验证
            phoneMsg: {
                username_phone: '',
            },
            rules: {
                username_phone: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
            },
            // 邮箱验证
            emailMsg: {
                username:''
            },
            erules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
            },
            resetMsg: {
                username: '',
                phone: '',
                code: '',
                password: '',
                PasswordAgain:''
            },
            rrules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur', required: true}
                ],
                code: [
                    { required: true, message: '请输入手机验证码', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入新密码', trigger: 'blur' }
                ],
                PasswordAgain:[
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur', required: true }
                ],
            },
            type: null,
            
        }
    },
    created(){
        var url = window.location.search.split('=')
        if(url[0] === '?token'){
            this.step = 3
            this.type = 'email'
            this.token = url[1]
        }
    },
    methods:{
        // 手机找回
        phoneFind(){
            this.step = 2
            this.step2 = 1
            this.type = 'phone'
            this.$refs.emailMsg.resetFields()
        },
        // 邮箱找回
        emailFind(){
            this.step = 2
            this.step2 = 2
            this.type = 'email'
            this.$refs.phoneMsg.resetFields()
        },
        // 发送短信验证
        reactPasswordPhone(){
            this.$refs.phoneMsg.validate(valid => {
                if (valid) {
                    this.restCommon(this.phoneMsg.username_phone, 'phone')
                    this.resetMsg.username = this.phoneMsg.username_phone
                }
            })
        },
        // 发送验证邮件
        sendEmail() {
            this.$refs.emailMsg.validate(valid => {
                if (valid) {
                    this.restCommon(this.emailMsg.username, 'email')
                }
            })
        },
        restCommon(name, type) { 
            var fd = new FormData()
            fd.append('username', name)
            fd.append('type', type)
            axios.post(this.GLOBAL.BASE_URL1 + '/user/rest_password',fd)
            .then(res=>{
                if (res.data.status_code == 200) {
                    if(type == 'phone') this.step = 3
                    this.$message({
                        type: 'success',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
         // 重置密码
        find() {
            if (this.type == 'phone') {
                this.$refs.resetMsg.validate(valid => {
                    if (valid) {
                        var fd = new FormData()
                        fd.append('password', this.resetMsg.password)
                        fd.append('username', this.resetMsg.username)
                        fd.append('phone', this.resetMsg.phone)
                        fd.append('code', this.resetMsg.code)
                        this.findCommon(fd)
                    }
                })
            } else if (this.type == 'email') {
                this.$refs.resetMsg.validate(valid => {
                    if (valid) {
                        var fd = new FormData()
                        fd.append('password', this.resetMsg.password)
                        this.findCommon(fd, 'email')
                    }
                })
            }
            
        },
        findCommon(fd, type) {
            var url=''
            if (type == 'email') {
                url =  this.GLOBAL.BASE_URL1 + '/user/findPassword?token='+this.token
            } else {
                url = this.GLOBAL.BASE_URL1 + '/user/findPassword'
            }
            axios.post(url,fd)
            .then(res=>{
                console.log(res);
                if(res.data.code == 200){
                    this.$message({
                        type: 'success',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                    this.$router.push('/login')
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
         },
    }
}

